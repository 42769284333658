import Hero from './Hero.js'
import About from './About.js'
import Valorem from './Valorem.js'
import HomeServices from './HomeServices'
import Value from './Value.js'
import History from './History.js'
import Location from './Location.js'
import Footer from './Footer.js'
import '../App.css';
import '../Mobile.css';

function App() {
    return (
        <>
            < Hero/>
            {/*< About/>*/}
            < Valorem/>
            < HomeServices/>
            < Value/>
            < History/>
            < Location/>
            < Footer/>
        </>
    );
}

export default App;
