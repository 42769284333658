import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';


const SCompany = () => {
    return (
        <>
            <div className="sec-services">
                <div id="service-invest" className="hero-services ser-investment">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                INVESTMENT & PRIVATE EQUITY
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Indonesia has the 4th largest population in the world and the largest economy in Southeast Asia.
                            With an ever growing middle class and as one of the largest spenders on IT in the region, it holds one of the biggest economic growth potential in the world.
                            Indonesia is currently the world's 16th largest economy with a GDP of more than $1 trillion (2020).
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">SUPPORTING SERVICES</h2>
                        </div>
                    </div>
                    <div className="investment-box">
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    TRADITIONAL
                                </p>
                                <p className="visa-types-tag">
                                    CRYPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Market Research Intelligence
                            </p>
                            <p className="investment-grid-desc">
                                Our thorough market research helps our clients predict market trends to adapt their products and services accordingly.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    TRADITIONAL
                                </p>
                                <p className="visa-types-tag">
                                    CRYPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Competition Research
                            </p>
                            <p className="investment-grid-desc">
                                With years of experience, our team of researches provide a full list of key competitors and come up with the best winning strategies.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    TRADITIONAL
                                </p>
                                <p className="visa-types-tag">
                                    CRYPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Market Entry
                            </p>
                            <p className="investment-grid-desc">
                                We come up with the best entry strategies for our clients to deal with all bureaucracy and comply with local laws and regulations.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    CRYPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Asset Management
                            </p>
                            <p className="investment-grid-desc">
                                Our crypto trader experts take advantage of both technical and market analysis to safely manage assets at low risk and organic strategies.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    CRYPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Risk Assessment
                            </p>
                            <p className="investment-grid-desc">
                                Valorem's risk assessment deeply study the cryptocurrency market's risk of market manipulation and high volatility to securely protect your crypto assets.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    CRYPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Liquidity Consulting
                            </p>
                            <p className="investment-grid-desc">
                                We induce and increase real transaction volumes rather against abnormal self-tradings to secure meaningful volume.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany