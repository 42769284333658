import Rec from "../img/side-rec.svg";
import { HashLink as Links } from 'react-router-hash-link';
import ArrowR from '../img/arrow-right.png'


const HomeServices = () => {
    return (
        <div className="sec-services-home">
            <div className="div-services-home">
                <div className="services-title-box dpf-rcs">
                    <img src={Rec} className="side-rec"/>
                    <h2 className="title-h2">LAYANAN KAMI</h2>
                </div>
                <div className="home-serv-grid">
                    <Links to="/id/services/company-registration#serv-company" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                REGISTRASI PERUSAHAAN
                            </p>
                            <p className="home-serv-desc">
                                Kami membantu Anda untuk mengambil langkah awal dalam menentukan jenis perusahaan terbaik sesuai dengan layanan yang disediakan, serta menangani semua dokumen yang diperlukan agar sesuai dengan regulasi setempat.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Pelajari Lebih Lanjut</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/id/services/visa-documentation#ser-visa" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                DOKUMEN VISA
                            </p>
                            <p className="home-serv-desc">
                                Dari dukungan visa hingga pendaftaran pekerja, kami menyusun laporan komprehensif berdasarkan profil perusahaan Anda untuk menawarkan dukungan penuh dalam memperoleh visa kerja.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Pelajari Lebih Lanjut</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/id/services/investment-pe#service-invest" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                INVESTASI & EKUITAS PRIBADI
                            </p>
                            <p className="home-serv-desc">
                                Dari pengurusan visa hingga pendaftaran pekerja, kami menyusun laporan komprehensif berdasarkan profil perusahaan Anda untuk menawarkan dukungan penuh dalam memperoleh izin kerja sekaligus ketenagakerjaan.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Pelajari Lebih Lanjut</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/id/services/tax-accounting#service-tax" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                PAJAK & AKUNTANSI
                            </p>
                            <p className="home-serv-desc">
                                Dengan pengalaman bertahun-tahun, tim keuangan kami akan membantu perjalanan Anda menghadapi dunia akuntansi dan pajak yang kompleks. Kami membantu Anda agar tetap sesuai dengan seluruh undang-undang serta meminimalkan pajak.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Pelajari Lebih Lanjut</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/id/services/merges-acquisitions#service-ma" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                MERGER & AKUISISI
                            </p>
                            <p className="home-serv-desc">
                                Pada sektor M&A, kami memberikan dukungan bagi perusahaan untuk melakukan merger atau akuisisi perusahaan lain dengan potensi pertumbuhan yang tinggi. Kami tidak hanya mengurus administrasi dokumen tetapi juga mengantarkan Anda pada jaringan luas yang kami miliki untuk kemitraan di masa depan.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Pelajari Lebih Lanjut</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                </div>
            </div>
        </div>
    )
}

export default HomeServices