import Rec from "../img/side-rec.svg";
import { HashLink as Links } from 'react-router-hash-link';
import ArrowR from '../img/arrow-right.png'


const HomeServices = () => {
    return (
        <div className="sec-services-home">
            <div className="div-services-home">
                <div className="services-title-box dpf-rcs">
                    <img src={Rec} className="side-rec"/>
                    <h2 className="title-h2">서비스</h2>
                </div>
                <div className="home-serv-grid">
                    <Links to="/ko/services/company-registration#serv-company" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title ko">
                                회사 등기
                            </p>
                            <p className="home-serv-desc ko">
                                귀사의 서비스에 가장 적합한 회사 유형을 선택하고 현지 규정을 준수하는 데 필요한 모든 문서를 처리할 수 있도록 지원하여 성공적인 첫 단계를 밟을 수 있도록 돕습니다.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>더 알아보기</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/ko/services/visa-documentation#ser-visa" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title ko">
                                비자 서류
                            </p>
                            <p className="home-serv-desc ko">
                                비자 스폰서십부터 근로자 등록까지 귀사의 프로필을 바탕으로 종합 보고서를 작성하여 취업 비자 취득에 대한 전폭적인 지원을 제공합니다.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>더 알아보기</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/ko/services/investment-pe#service-invest" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title ko">
                                투자 및 사모 펀드
                            </p>
                            <p className="home-serv-desc ko">
                                전문가로 이루어진 팀이 외국 기업과 개인들의 요구에 맞는 여러 산업에서 다양한 선택을 지원합니다. 우리는 투자자들이 수익을 극대화할 수 있는 가장 효과적인 방법을 제시하기 위해 법적 등록부터 세금 준수까지 모든 패키지를 제공합니다.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>더 알아보기</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/ko/services/tax-accounting#service-tax" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title ko">
                                세무 및 회계
                            </p>
                            <p className="home-serv-desc ko">
                                다년간의 경험을 바탕으로, 우리 재무팀은 회계와 세무의 복잡한 세계를 헤쳐 나갈 수 있도록 도와드립니다. 세금을 최소화하고 모든 법을 준수할 수 있도록 도와드립니다.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>더 알아보기</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/ko/services/merges-acquisitions#service-ma" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title ko">
                                인수 및 합병
                            </p>
                            <p className="home-serv-desc ko">
                                잠재성장률이 높은 다른 기업과 합병하거나 인수할 수 있도록 지원합니다. 우리는 적절한 문서를 처리하고 향후 파트너십을 위해 고객에게 방대한 네트워크를 제공합니다.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>더 알아보기</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                </div>
            </div>
        </div>
    )
}

export default HomeServices