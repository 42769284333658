import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div className="sec-services">
                <div className="hero-services ser-visa">
                    <div id="ser-visa" className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                PENGURUSAN VISA
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Tim konsultan VISA dan Imigrasi kami memiliki pengalaman bertahun-tahun dalam memproses ribuan VISA hingga saat ini.
                            Kami memastikan terkait visa dan dokumen yang diperlukan perusahaan akan disiapkan dengan benar sesuai dengan undang-undang yang ada.
                            Khusus untuk perusahaan, kami menyusun laporan komprehensif berdasarkan profil perusahaan Anda untuk menawarkan dukungan penuh dalam memperoleh visa kerja.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">JENIS VISA</h2>
                        </div>
                        <div className="visa-types-grid">
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                <p className="visa-grid-p">
                                    Kerja
                                </p>
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                <p className="visa-grid-p">
                                    Pekerja Lepas
                                </p>
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    B211
                                </p>
                                Kunjungan Satu Kali Perjalanan
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    D212
                                </p>
                                Kunjungan Beberapa Kali Perjalanan
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Investor
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Lansia / Pensiun
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Keluarga
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Pasangan
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Pelajar
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAP
                                </p>
                                Tinggal Tetap
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Calling
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    VOA
                                </p>
                                Perpanjangan Kunjungan
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Deportasi
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Izin Imigrasi
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Izin Perpanjangan Tinggal
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Konsultasi Imigrasi
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Kewarganegaraan (KITAS)
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    SKJ
                                </p>
                                Surat Keterangan Jalan (SKJ)
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    SKCK
                                </p>
                                SKCK
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany