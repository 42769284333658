import ServiceTab from './ServiceTab.js';
import './Services.css'
import '../../Mobile.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';


const SCompany = () => {
    return (
        <>
        <div className="sec-services">
            <div id="serv-company" className="hero-services ser-company">
                <div className="hero-services-bg"></div>
                <div className="hero-services-cont">
                    <div className="services-title-box">
                        <img src={RecW} className="side-rec"/>
                        <h2 className="services-title">
                            COMPANY REGISTRATION
                        </h2>
                    </div>
                </div>
            </div>
            <ServiceTab />
            <div className="div-services">
                <div className="service-desc-div">
                    <p className="service-desc">
                        Establishing a company is the first step to starting your business in Indonesia. We advise you on how to choose the best company type according to your investment needs and acquire all necessary permits and documents in order to start your own business in Indonesia.
                        Our experts will constantly communicate with you to understand your needs and tailor our services accordingly. The company registration procedures will vary based on each occasion.
                    </p>
                    <div className="title-box-services dpf-rcs">
                        <img src={Rec} className="side-rec"/>
                        <h2 className="title-h2">COMPANY TYPES</h2>
                    </div>
                    <div className="company-types">
                        <div className="company-type">
                            <p className="visa-types-tag">
                                PMA
                            </p>
                            <p className="c-type-title">
                                Penanaman Modal Asing (Foreign Direct Investment)
                            </p>
                            <p className="c-type-desc">
                                PMA is the best option for foreign companies or individuals looking for a 100% foreign ownership company type in Indonesia. PMA companies might face restrictions as some sectors do not allow 100% foreign-owned companies to act.
                            </p>
                        </div>
                        <div className="company-type">
                            <p className="visa-types-tag">
                                PMDN
                            </p>
                            <p className="c-type-title">
                                Penemanan Modal Asing Negeri (Local Company)
                            </p>
                            <p className="c-type-desc">
                                PMDN are companies owned 100% by local investment (individual and companies). PMDN companies can be established fast and with less requirements when compared to PMA companies.
                            </p>
                        </div>
                        <div className="company-type">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    KPPA
                                </p>
                                <p className="visa-types-tag">
                                    KP3A
                                </p>
                                <p className="visa-types-tag">
                                    BUJKA
                                </p>
                            </div>
                            <p className="c-type-title">
                                Representative Office
                            </p>
                            <p className="c-type-desc">
                                Representative Offices are the best options for those seeking to explore the Indonesian market with low risks. The most common RO type of companies are KPPA (General), KP3A (Trading Business), and BUJKA (Contruction Company).
                            </p>
                        </div>
                    </div>
                    <div className="title-box-services dpf-rcs">
                        <img src={Rec} className="side-rec"/>
                        <h2 className="title-h2">COMPARISON TABLE</h2>
                    </div>
                    <div className="table-hor">
                        <table className="company-table">
                            <tr>
                                <th className="companyre-con noborder-l padding-inline">
                                    Company Type
                                </th>
                                <th className="companyre-con">
                                    Nature
                                </th>
                                <th className="companyre-con">
                                    Structure
                                </th>
                                <th className="companyre-con">
                                    Capital Requirement
                                </th>
                            </tr>
                            <tr>
                                <td className="noborder-l">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">PMA</p>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">Allows profit-generating business activities
                                        </li>
                                        <li className="companyre-li">Allows up to 100% foreign ownership
                                        </li>
                                        <li className="companyre-li">Ideal for business sectors that are open for foreign investment
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">Minimum two shareholders (corporate/individual)
                                        </li>
                                        <li className="companyre-li">At least 1 local director
                                        </li>
                                        <li className="companyre-li">At least 1 commissioner (can be a foreigner with a valid work permit)
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-capital">
                                        <li className="companyre-li">Minimum IDR 10 billion of investment
                                        </li>
                                        <li className="companyre-li">At least IDR 10 billion should be provided as a paid-up capital
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="noborder-l">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">PMDN</p>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">Foreign employees are only allowed in medium to large-sized companies
                                        </li>
                                        <li className="companyre-li">No foreign ownership allowed
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">Minimum two shareholders (corporate/individual)
                                        </li>
                                        <li className="companyre-li">At least 1 local director
                                        </li>
                                        <li className="companyre-li">At least 1 local commissioner
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-capital">
                                        <li className="companyre-li">Small: IDR 1 billion ~ 5 billion
                                        </li>
                                        <li className="companyre-li">Medium: IDR 5 billion ~ 10 billion
                                        </li>
                                        <li className="companyre-li">Large: IDR 10 billion ~
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="noborder-l noborder-b">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">Representative Office</p>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">None to limited financial income activities
                                        </li>
                                        <li className="companyre-li">100% foreign ownership allowed
                                        </li>
                                        <li className="companyre-li">Focused on market survey activities
                                        </li>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">No shareholder or director required
                                        </li>
                                        <li className="companyre-li">One chief executive is sufficient (KPPA & KP3A)
                                        </li>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-capital">
                                        <p className="companyre-li">-
                                        </p>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default SCompany