import Rec from "../img/side-rec.svg";
import { HashLink as Links } from 'react-router-hash-link';
import ArrowR from '../img/arrow-right.png'


const HomeServices = () => {
    return (
        <div className="sec-services-home">
            <div className="div-services-home">
                <div className="services-title-box dpf-rcs">
                    <img src={Rec} className="side-rec"/>
                    <h2 className="title-h2">OUR SERVICES</h2>
                </div>
                <div className="home-serv-grid">
                    <Links to="/services/company-registration#serv-company" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                COMPANY REGISTRATION
                            </p>
                            <p className="home-serv-desc">
                                We help companies take their first steps by helping them choose the best company type for their services and deal with all documentations necessary to keep in compliance with local regulations.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Learn More</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/services/visa-documentation#ser-visa" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                VISA DOCUMENTATION
                            </p>
                            <p className="home-serv-desc">
                                From visa sponsorship to worker registration, we create a comprehensive report based on your company’s profile to offer full support on working visa acquirement.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Learn More</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/services/investment-pe#service-invest" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                INVESTMENT & PRIVATE EQUITY
                            </p>
                            <p className="home-serv-desc">
                                The investment team helps foreign companies and individuals choose from a diverse set of options in multiple industries that fit their needs. We offer a full package from legal registrations to tax compliance to bring out the most effective way for investors to maximize their profits.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Learn More</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/services/tax-accounting#service-tax" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                TAX & ACCOUNTING
                            </p>
                            <p className="home-serv-desc">
                                With years of experience, our financial team will help you navigate through the complex world of accounting and taxes. We help you stay in pair with all laws as well as minimize taxation.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Learn More</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                    <Links to="/services/merges-acquisitions#service-ma" className="home-serv-grids">
                        <div className="home-serv-content">
                            <p className="home-serv-title">
                                MERGES & ACQUISITION
                            </p>
                            <p className="home-serv-desc">
                                In the M&A sector, we provide support for companies to merge with or acquire other companies with high potential growth. We not only take care of the proper documentations but also introduce our clients to our vast network for future partnerships.
                            </p>
                        </div>
                        <div className="ser-learnmore">
                            <p>Learn More</p>
                            <img src={ArrowR} className="service-arrow"/>
                        </div>
                    </Links>
                </div>
            </div>
        </div>
    )
}

export default HomeServices