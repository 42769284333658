import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div id="service-tax" className="sec-services">
                <div className="hero-services ser-tax">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                TAX & ACCOUNTING
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Dealing with taxes and accounting is daunting regardless of where you are, yet critical when doing business.
                            Valorem's team of legal and business consultants are equipped with the best tools and experience in the market to help you keep your books organized.
                            We offer services on personal income tax, corporate tax, and property tax.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">T&A SERVICES</h2>
                        </div>
                        <div className="tax-desc-box">
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    Personal Income Tax
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc">
                                        Personal income taxes are often withhold by companies who are obligated to pay to the Indonesian government.
                                        Tax amount may vary from 5% to 30% according to income as well as the employee status, if it's a resident taxpayer or a non-resident taxpayer.
                                        Besides the standard tax regulations such as the non-taxable income of Rp. 54 million there are still multiple ways to deduct tax even further.
                                    </p>
                                </div>
                            </div>
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    Corporate Tax
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc">
                                        Corporate taxes are more complicated in its essence as there are too many variables to take into consideration. Besides the standard tax rate of 25% applied to all companies in Indonesia, there are several exemptions and benefits to reduce tax rate even further.
                                        Although both domestic and foreign companies are obliged to pay taxes, the case for foreign companies often turns out to be more complicated.
                                        Even without a permanent establishment in Indonesia, foreign companies must still settle its tax liabilities.
                                    </p>
                                </div>
                            </div>
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    Property Tax
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc">
                                        With the constant economic growth and seen as a tourism hub, many foreign investors turned their capital to Indonesia.
                                        Especially in Bali, it's critical to understand all property-related tax liabilities that may occur.
                                        Property taxes in Indonesia a mainly divided into Lease Tax, Land and Building Tax, and Construction Tax.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany