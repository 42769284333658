import Logo from '../img/valorem_logo_white.svg'
import { BrowserRouter, Link, Route } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link';

const Footer = () => {
    return (
        <div className="sec-footer dpf-ccc">
            <div className="div-footer dpf-ccs">
                <div className='footer-top dpf-ccc'>
                    <div className="footer-left dpf-ccc">
                        <img className="footer-logo" src={Logo}/>
                        <p className="footer-desc">Menginspirasi dan Mengoptimalkan Nilai Anda</p>
                    </div>
                    <div className="footer-right">
                        <div className="footer-menus dpf-rcc">
                            <Links to="/id/#anc-home" className="footer-menu">Beranda</Links>
                            <div className="footer-division"></div>
                            <Links to="/id/#anc-about" className="footer-menu">Tentang Kami</Links>
                            <div className="footer-division"></div>
                            <Links to="/id/services/company-registration#serv-company" className="footer-menu">Layanan</Links>
                            <div className="footer-division"></div>
                            <Links to="/id/#anc-values" className="footer-menu">Keunggulan Kami</Links>
                            <div className="footer-division"></div>
                            <Links to="/id/#anc-history" className="footer-menu">Riwayat Kami</Links>
                            <div className="footer-division"></div>
                            <Links to="/id/#anc-location" className="footer-menu">Hubungi Kami</Links>
                        </div>
                    </div>
                </div>
                <div className="footer-bot dpf-ccc">
                    <div className="footer-d"></div>
                    <p className="copyright">2022 &copy; The Valorem Partners. All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer