

import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div id="service-ma" className="sec-services">
                <div className="hero-services ser-ma">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                인수 및 합병
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Valorem은 빠르게 변화하는 인도네시아 시장에서 기업들이 매년 M&A 거래 가치와 속도를 높일 수 있도록 지원합니다. 거래 과정 전반의 파트너로서, 우리는 특정 부문의 성장 기회와 사업부 내의 개선 사항을 모두 파악할 수 있습니다.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">M&A 서비스</h2>
                        </div>
                    </div>
                    <div className="ma-guide-div">
                        <div className="ma-guide">
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    합병
                                </p>
                                <p className="ma-guide-desc ko">
                                    모든 자산과 부채는 한 부분으로 완전히 이전되고 나머지 회사는 완전히 청산됩니다.
                                </p>
                            </div>
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    통합
                                </p>
                                <p className="ma-guide-desc ko">
                                    모든 회사가 완전히 청산되고 새로운 법인을 형성합니다. 모든 자산과 부채는 새로 형성된 기업에 완전히 이전됩니다.
                                </p>
                            </div>
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    주식 및 자산 인수
                                </p>
                                <p className="ma-guide-desc ko">
                                    당사자가 지배권의 변경을 초래하는 회사의 주식 또는 자산을 취득합니다.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ma-box">
                        <div className="title-box-services dpf-rcs ma-40">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">주요 법률 및 규정</h2>
                        </div>
                        <p className="ma-laws">
                            1.&nbsp;&nbsp;&nbsp; 자본 시장에 관한 1995 년 법률 제 8호(자본시장법)
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            2.&nbsp;&nbsp;&nbsp; 주식회사의 합병, 통합, 인수에 관환 1998년 정부 규정 제 27 호
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            3.&nbsp;&nbsp;&nbsp; 독점 및 불공정 사업 경쟁 금지에 관한 1999년 법률 제 5 호
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            4.&nbsp;&nbsp;&nbsp; 인력에 관한 2003 년 법률 제 13 호
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            5.&nbsp;&nbsp;&nbsp; 투자에 관한 2007 년 법류 제 25 호
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            6.&nbsp;&nbsp;&nbsp; 주식회사에 관한 2007 년 법률 제 40호(회사법)
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            7.&nbsp;&nbsp;&nbsp; 독점적 관행 및 불공정 사업 경쟁을 초래할 수 있는 기업 합병 및 통합, 주식 인수에 관한 2010 년 정부 규정 제 57 호
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            8.&nbsp;&nbsp;&nbsp; 독점적 관행 및 불공정 사업 경쟁을 초래할 수 있는 기업 합병 및 통합, 주식 인수 평가에 관한 2019년 KPPU 법 제 3호
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            9.&nbsp;&nbsp;&nbsp; 공기업의 합병 및 통합에 관한 OJK 규정 74/POJK.04/2016
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            10.&nbsp;&nbsp;&nbsp; 공기업 인수에 관한 OJK 규정 9/POJK.04/2018
                        </p>
                        <div className="ma-box-line"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany