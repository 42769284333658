import VIcon from '../img/valorem-icon-black.svg'
import Values from '../img/valuesKO.png'
import Icon from '../img/valorem-icon-black.svg'
import Rec from "../img/side-rec-black.svg";

const Value = () => {
    return (
        <div id="anc-values" className="sec-value">
            <div className="div-value">
                <div id="mobile" className="title-box-biz dpf-rcs m-20">
                    <img src={Rec} className="side-rec"/>
                    <h2 className="title-h2">회사의 가치</h2>
                </div>
                <div className="desktop value-title-box">
                    <p className="value-title">회사의<br/>가치</p>
                    <img src={VIcon} className="value-icon"/>
                </div>
                <div className="value-content-box">
                    <img src={Values} className="value-img desktop"/>
                    <div className="div-value-mob mobile">
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">합치</p>
                        </div>
                        <p className="value-desc">우리는 고객에게 맞는 최선의 서비스 제공하기 위해 고객의 니즈 및 관심에 맞는 서비스를 제공합니다.
                        </p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">시너지</p>
                        </div>
                        <p className="value-desc">우리의 파트너십은 계약 범위를 넘어 미래에 더 많은 기회를 제공합니다.
                        </p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">정직성</p>
                        </div>
                        <p className="value-desc">우리는 타협 없이 최고의 윤리적 기준을 준수합니다.
                        </p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">현명</p>
                        </div>
                        <p className="value-desc">우리는 고객의 요구에 정확하게 부합하는 협력 채널을 구축하고 유지합니다.
                        </p>
                        <div className="value-line"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Value