import ServiceTab from './ServiceTab.js';
import './Services.css'
import '../../Mobile.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';


const SCompany = () => {
    return (
        <>
        <div className="sec-services">
            <div id="serv-company" className="hero-services ser-company">
                <div className="hero-services-bg"></div>
                <div className="hero-services-cont">
                    <div className="services-title-box">
                        <img src={RecW} className="side-rec"/>
                        <h2 className="services-title">
                            JASA PENDIRIAN PERUSAHAAN
                        </h2>
                    </div>
                </div>
            </div>
            <ServiceTab />
            <div className="div-services">
                <div className="service-desc-div">
                    <p className="service-desc">
                        Mendirikan perusahaan merupakan langkah awal untuk memulai bisnis Anda di Indonesia. Kami memberi saran dalam memilih jenis perusahaan terbaik sesuai dengan kebutuhan investasi Anda serta kepengurusan semua izin dan dokumen yang diperlukan untuk memulai bisnis Anda sendiri di Indonesia.
                        Tim kami akan selalu berkomunikasi dalam memahami kebutuhan Anda agar layanan kami kepada Anda tetap sesuai.
                        Prosedur pendaftaran perusahaan akan bersifat variatif berdasarkan setiap kondisi.
                    </p>
                    <div className="title-box-services dpf-rcs">
                        <img src={Rec} className="side-rec"/>
                        <h2 className="title-h2">JENIS PERUSAHAAN</h2>
                    </div>
                    <div className="company-types">
                        <div className="company-type">
                            <p className="visa-types-tag">
                                PMA
                            </p>
                            <p className="c-type-title">
                                Penanaman Modal Asing
                            </p>
                            <p className="c-type-desc">
                                PMA adalah pilihan terbaik bagi perusahaan maupun individu asing yang mencari jenis perusahaan dengan 100% kepemilikan asing di Indonesia. Perusahaan PMA cenderung menghadapi pembatasan, karena beberapa sektor bisnis tidak mengizinkan 100% perusahaan milik asing untuk beroperasi.
                            </p>
                        </div>
                        <div className="company-type">
                            <p className="visa-types-tag">
                                PMDN
                            </p>
                            <p className="c-type-title">
                                Penemanan Modal Asing Negeri
                            </p>
                            <p className="c-type-desc">
                                PMDN adalah perusahaan yang dimiliki 100% oleh investor lokal (perorangan dan perusahaan). Perusahaan PMDN dapat didirikan secara cepat dan dengan persyaratan yang lebih ringkas jika dibandingkan dengan perusahaan PMA.
                            </p>
                        </div>
                        <div className="company-type">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    KPPA
                                </p>
                                <p className="visa-types-tag">
                                    KP3A
                                </p>
                                <p className="visa-types-tag">
                                    BUJKA
                                </p>
                            </div>
                            <p className="c-type-title">
                                Kantor Perwakilan
                            </p>
                            <p className="c-type-desc">
                                Kantor Perwakilan menjadi pilihan terbaik bagi mereka yang ingin menjelajahi pasar Indonesia dengan risiko rendah. Jenis perusahaan ini yang paling umum adalah KPPA (Umum), KP3A (Bisnis Perdagangan), dan BUJKA (Perusahaan Konstruksi).
                            </p>
                        </div>
                    </div>
                    <div className="title-box-services dpf-rcs">
                        <img src={Rec} className="side-rec"/>
                        <h2 className="title-h2">TABEL PERBANDINGAN</h2>
                    </div>
                    <div className="table-hor">
                        <table className="company-table">
                            <tr>
                                <th className="companyre-con noborder-l padding-inline">
                                    Jenis Perusahaan
                                </th>
                                <th className="companyre-con">
                                    Karakteristik
                                </th>
                                <th className="companyre-con">
                                    Struktur
                                </th>
                                <th className="companyre-con">
                                    Syarat Modal
                                </th>
                            </tr>
                            <tr>
                                <td className="noborder-l">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">PMA<br/>(Penanaman Modal Asing)</p>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">Memungkinkan aktivitas bisnis yang menghasilkan keuntungan
                                        </li>
                                        <li className="companyre-li">Memungkinkan 100% kepemilikan asing
                                        </li>
                                        <li className="companyre-li">Ideal bagi sektor bisnis yang terbuka untuk investasi asing
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">Minimal dua pemegang saham (korporasi/perorangan)
                                        </li>
                                        <li className="companyre-li">Setidaknya 1 direktur lokal
                                        </li>
                                        <li className="companyre-li">Setidaknya 1 komisaris (diperbolehkan pula untuk orang asing dengan visa kerja)
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-capital">
                                        <li className="companyre-li">Minimum investasi 10 miliar rupiah
                                        </li>
                                        <li className="companyre-li">Setidaknya 10 miliar rupiah harus disediakan sebagai modal yang disetor
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="noborder-l">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">PMDN<br/>(Penanaman Modal Dalam Negeri)</p>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">Karyawan asing hanya diperbolehkan dalam perusahaan menengah hingga besar
                                        </li>
                                        <li className="companyre-li">Tidak diizinkan kepemilikan asing
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">Minimal dua pemegang saham (korporasi/perorangan)
                                        </li>
                                        <li className="companyre-li">Setidaknya 1 direktur lokal
                                        </li>
                                        <li className="companyre-li">Setidaknya 1 komisaris lokal
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-capital">
                                        <li className="companyre-li">Kecil: Rp 1 miliar - 5 miliar
                                        </li>
                                        <li className="companyre-li">Medium: Rp 5 miliar - 10 miliar
                                        </li>
                                        <li className="companyre-li">Besar: Rp 10 miliar -
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="noborder-l noborder-b">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">Kantor Perwakilan</p>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">Aktivitas pemasukan keuangan yang tidak dibatasi
                                        </li>
                                        <li className="companyre-li">100% kepemilikan asing diperbolehkan
                                        </li>
                                        <li className="companyre-li">Fokus pada aktivitas survei pasar
                                        </li>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">Tidak memerlukan pemegang saham atau direktur
                                        </li>
                                        <li className="companyre-li">Cukup satu kepala eksekutif (KPPA & KP3A)
                                        </li>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-capital">
                                        <p className="companyre-li">-
                                        </p>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default SCompany