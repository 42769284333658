

const Location = () => {
    return (
        <div id="anc-location" className="sec-location dpf-ccc">
            <div className="div-location dpf-rcc">
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe width="100%" height="420" id="gmap_canvas"
                                src="https://maps.google.com/maps?q=Graha%20Surveyor%20Indonesia%20Lantai%2019%20Suite%201901,%20Jl.%20Gatot%20Subroto%20No.%20Kav%2056,%20RT.1/RW.4,%20Kuningan%20Timur,%20Kecamatan%20Setiabudi,%20Kota%20Jakarta%20Selatan,%20Daerah%20Khusus%20Ibukota%20Jakarta%2012950&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                </div>
                <div className="location-box dpf-css">
                    <div className="div-loc">
                        <p className="location-subt">Kantor Jakarta</p>
                        <p className="location-add">
                            Graha Surveyor Indonesia Lantai 19 Suite 1901,
                            Jl. Gatot Subroto No. Kav 56, RT.1/RW.4, Kuningan
                            Timur, Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12950</p>
                    </div>
                    <div className="div-mail">
                        <p className="location-subt">Hubungi Kami</p>
                        <a className="mail-p" href="mailto:contact@thevalorempartners.com">contact@thevalorempartners.com</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location