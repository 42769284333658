import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div className="sec-services">
                <div className="hero-services ser-visa">
                    <div id="ser-visa" className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                비자 서류
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            우리 VISA 및 이민 컨설턴트 팀은 지금까지 현지에서 수천 건의 VISA를 처리한 수년간의 경험을 가지고 있습니다.
                            우리는 모든 회사와 개인이 모든 VISA와 필요한 서류를 현지 법률을 준수하여 올바르게 준비하도록 보장합니다.
                            특히 기업의 경우, 귀사의 프로필을 바탕으로 종합 보고서를 작성하여 취업 비자 취득에 대한 전폭적인 지원을 제공합니다.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">비자 유형</h2>
                        </div>
                        <div className="visa-types-grid">
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                <p className="visa-grid-p">
                                    취업 비자
                                </p>
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                <p className="visa-grid-p">
                                    프리랜서 취업 비자
                                </p>
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    B211
                                </p>
                                단일 입국 비자
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    D212
                                </p>
                                복수 입국 비자
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                투자자 비자
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                은퇴 비자
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                동반 비자
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                배우자 비자
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                유학 비자
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAP
                                </p>
                                영주권 비자
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                콜링 비자
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    VOA
                                </p>
                                도착 비자 연장
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                국외 추방 허가
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                이민 허가
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                오버스테이 허가
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                이민 상담
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                시민권
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    SKJ
                                </p>
                                여행 허가증
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    SKCK
                                </p>
                                경찰 허가증
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany