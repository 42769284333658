import './Tab.css';
import { BrowserRouter, Link, Route } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link';


const ServiceTab = () => {
    return (
        <>
            <div className="desktop sec-tab">
                <div className="div-tab">
                    <Links to="/ko/services/company-registration#serv-company" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title ko">회사 등기</span>
                    </Links>
                    <Links to="/ko/services/visa-documentation#ser-visa" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title ko">비자 서류</span>
                    </Links>
                    <Links to="/ko/services/investment-pe#service-invest" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title ko">투자 및 사모펀드</span>
                    </Links>
                    <Links to="/ko/services/tax-accounting#service-tax" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title ko">세무 및 회계</span>
                    </Links>
                    <Links to="/ko/services/merges-acquisitions#service-ma" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title ko">인수 및 합병</span>
                    </Links>
                </div>
            </div>
            <div className="mobile sec-tab">
                <div className="div-tab">
                    <Link to="/ko/services/company-registration" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">회사 등기</span>
                    </Link>
                    <Link to="/ko/services/visa-documentation" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">비자 서류</span>
                    </Link>
                    <Link to="/ko/services/investment-pe" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">투자 및 사모펀드</span>
                    </Link>
                    <Link to="/ko/services/tax-accounting" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">세무 및 회계</span>
                    </Link>
                    <Link to="/ko/services/merges-acquisitions" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">인수 및 합병</span>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ServiceTab