import VIcon from '../img/valorem-icon-black.svg'
import Values from '../img/values.png'
import Icon from '../img/valorem-icon-black.svg'
import Rec from "../img/side-rec-black.svg";

const Value = () => {
    return (
        <div id="anc-values" className="sec-value">
            <div className="div-value">
                <div id="mobile" className="title-box-biz dpf-rcs m-20">
                    <img src={Rec} className="side-rec"/>
                    <h2 className="title-h2">OUR VALUES</h2>
                </div>
                <div className="desktop value-title-box">
                    <p className="value-title">OUR<br/>VALUES</p>
                    <img src={VIcon} className="value-icon"/>
                </div>
                <div className="value-content-box">
                    <img src={Values} className="value-img desktop"/>
                    <div className="div-value-mob mobile">
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">Congruency</p>
                        </div>
                        <p className="value-desc">We align our interest and mindset with those of our clients' to deliver optimal services</p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">Synergy</p>
                        </div>
                        <p className="value-desc">Our partnerships extend beyond contracts for more opportunities in the future
                        </p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">Integrity</p>
                        </div>
                        <p className="value-desc">We demonstrate highest ethical and professional standards without compromise
                        </p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">Sagacity</p>
                        </div>
                        <p className="value-desc">We establish and maintain cooperative channels that cater precisely to our clients’ needs
                        </p>
                        <div className="value-line"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Value