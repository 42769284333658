import Rec from "../img/side-rec.svg";
import AboutBg from "../img/bg-blocks.png";


const Valorem = () => {
    return (
        <div id="anc-about" className="sec-valorem-home dpf-ccc">
            <div className="div-valorem-home dpf-rsc">
                <div className="div-val-left dpf-css">
                    <div className="title-box-val dpf-css">
                        {/*<img src={Rec} className="side-rec"/>*/}
                        <h2 className="title-h2 val0">WE ARE</h2>
                        <h2 className="title-h2 val">VALOREM</h2>
                    </div>
                    <div className="val-scroll">
                        <div className="line-div">
                            <div className="mouse-line"></div>
                            <div className="arrow-down"></div>
                        </div>
                        <div className="mouse-right">
                            <div className="mouse-border">
                                <div className="mouse-circle"></div>
                            </div>
                            <p className="mouse-scroll-txt">Scroll<br/>Down</p>
                        </div>
                    </div>
                </div>
                <div className="div-val-right">
                    <div className="val-txt">
                        <p className="valorem-txt ko">
                            인도네시아 블록체인 산업에 근간을 두고 있는 <b>Valorem Partners</b>는 다양한 업계의 전문 컨설턴트와 파트너사의 협력을 통해 고객에게 최적의 솔루션을 제공합니다.
                            <br/>
                            <br/>
                            당사는 2018년 첫 발돋움을 시작으로 재능 있고 열정적인  150명 이상의 인재들과 함께 빠르게 성장하고 있습니다.
                        </p>
                        <div className="about-left-bot">
                            <div className="about-line"></div>
                            <p className="about-val">THE VALOREM PARTNERS | 2022</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Valorem