import Logo from './img/valorem_logo_white.svg'
import { BrowserRouter, Link, Route } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link';
import React, {useState} from 'react';
import Globe from "./img/globe.svg";
import LangEN from "./img/lang-usa.svg";
import LangID from "./img/lang-id.svg";
import LangKO from "./img/lang-ko.svg";

const Navbar = () => {

    const [menuO,setMenuO] = useState('none');
    const [menuC,setMenuC] = useState('block');

    const handleClick = (index) => {
        switch (index) {
            case 0:
                setMenuO('block');
                setMenuC('none');
                return;
            case 1:
                setMenuO('none');
                setMenuC('block');
                return;
        }
    }


    return (
        <div className="sec-nav">
            <div className="div-nav">
                <Links to="/id#anc-home">
                    <img src={Logo} className="navlogo"/>
                </Links>
                <div className="nav-menus">
                    <Links to="/id#anc-home" className="nav-menu">Beranda</Links>
                    <Links to="/id#anc-about" className="nav-menu">Tentang Kami</Links>
                    <div className="nav-serv-drop">
                        <p className="nav-menu" onClick={()=>handleClick(0)}>Layanan</p>
                        <div className="nav-serv-box">
                            <div className="nav-serv-drops">
                                <Links to="/id/services/company-registration#serv-company" className="nav-s-drops">
                                    Jasa Pendirian Perusahaan
                                </Links>
                                <div className="dropdown-line"></div>
                                <Links to="/id/services/visa-documentation#ser-visa" className="nav-s-drops">
                                    Dokumen Visa
                                </Links>
                                <div className="dropdown-line"></div>
                                <Links to="/id/services/investment-pe#service-invest" className="nav-s-drops">
                                    Investasi & Private Equity
                                </Links>
                                <div className="dropdown-line"></div>
                                <Links to="/id/services/tax-accounting#service-tax" className="nav-s-drops">
                                    Pajak & Akuntansi
                                </Links>
                                <div className="dropdown-line"></div>
                                <Links to="/id/services/merges-acquisitions#service-ma" className="nav-s-drops">
                                    Merger & Akuisisi
                                </Links>
                            </div>
                        </div>
                    </div>
                    <Links to="/id#anc-values" className="nav-menu">Keunggulan Kami</Links>
                    <Links to="/id#anc-history" className="nav-menu">Riwayat Kami</Links>
                    <Links to="/id#anc-location" className="nav-menu">Hubungi Kami </Links>
                    <div className="nav-lang">
                        <div className="globe-div">
                            <img src={Globe} className="lang-globe"/>
                        </div>
                        <div className="nav-lang-box">
                            <div className="nav-lang-drop">
                                <Links to="/#anc-home" className="lang-icons">
                                    <img src={LangEN} className="lang-icon"/>
                                    <div className="langs">English</div>
                                </Links>
                                <div className="lang-div"></div>
                                <Links to="/id#anc-home" className="lang-icons">
                                    <img src={LangID} className="lang-icon"/>
                                    <div className="langs">Bahasa</div>
                                </Links>
                                <div className="lang-div"></div>
                                <Links to="/ko#anc-home" className="lang-icons">
                                    <img src={LangKO} className="lang-icon"/>
                                    <div className="langs" to="/ko#anc-home">한국어</div>
                                </Links>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar