import './Tab.css';
import { BrowserRouter, Link, Route } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link';


const ServiceTab = () => {
    return (
        <>
            <div className="desktop sec-tab">
                <div className="div-tab">
                    <Links to="/services/company-registration#serv-company" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">COMPANY REGISTRATION</span>
                    </Links>
                    <Links to="/services/visa-documentation#ser-visa" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">VISA DOCUMENTATION</span>
                    </Links>
                    <Links to="/services/investment-pe#service-invest" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">INVESTMENT & PRIVATE EQUITY</span>
                    </Links>
                    <Links to="/services/tax-accounting#service-tax" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">TAX & ACCOUNTING</span>
                    </Links>
                    <Links to="/services/merges-acquisitions#service-ma" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">MERGES & ACQUISITIONS</span>
                    </Links>
                </div>
            </div>
            <div className="mobile sec-tab">
                <div className="div-tab">
                    <Link to="/services/company-registration" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">COMPANY<br/>REGISTRATION</span>
                    </Link>
                    <Link to="/services/visa-documentation" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">VISA<br/>DOCUMENTATION</span>
                    </Link>
                    <Link to="/services/investment-pe" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">INVESTMENT &<br/>PRIVATE EQUITY</span>
                    </Link>
                    <Link to="/services/tax-accounting" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">TAX &<br/>ACCOUNTING</span>
                    </Link>
                    <Link to="/services/merges-acquisitions" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">MERGES &<br/>ACQUISITIONS</span>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ServiceTab