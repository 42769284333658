import Logo from './img/valorem_logo_white.svg'
import { BrowserRouter, Link, Route } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link';
import React, {useState} from 'react';
import LangEN from './img/lang-usa.svg';
import LangID from './img/lang-id.svg';
import LangKO from './img/lang-ko.svg';
import Globe from './img/globe.svg';

const Navbar = () => {

    const [menuO,setMenuO] = useState('none');
    const [menuC,setMenuC] = useState('block');

    const handleClick = (index) => {
        switch (index) {
            case 0:
                setMenuO('block');
                setMenuC('none');
                return;
            case 1:
                setMenuO('none');
                setMenuC('block');
                return;
        }
    }


    return (
        <div className="sec-nav">
            <div className="div-nav">
                <Links to="/ko#anc-home">
                    <img src={Logo} className="navlogo"/>
                </Links>
                <div className="nav-menus">
                    <Links to="/ko#anc-home" className="nav-menu">홈</Links>
                    <Links to="/ko#anc-about" className="nav-menu">소개</Links>
                    <div className="nav-serv-drop">
                        <p className="nav-menu" onClick={()=>handleClick(0)}>서비스</p>
                        <div className="nav-serv-box">
                            <div className="nav-serv-drops">
                                <Links to="/ko/services/company-registration#serv-company" className="nav-s-drops">
                                    회사 등기
                                </Links>
                                <div className="dropdown-line"></div>
                                <Links to="/ko/services/visa-documentation#ser-visa" className="nav-s-drops">
                                    비자 서류
                                </Links>
                                <div className="dropdown-line"></div>
                                <Links to="/ko/services/investment-pe#service-invest" className="nav-s-drops">
                                    투자 및 사모 펀드
                                </Links>
                                <div className="dropdown-line"></div>
                                <Links to="/ko/services/tax-accounting#service-tax" className="nav-s-drops">
                                    세무 및 회계
                                </Links>
                                <div className="dropdown-line"></div>
                                <Links to="/ko/services/merges-acquisitions#service-ma" className="nav-s-drops">
                                    인수 및 합병
                                </Links>
                            </div>
                        </div>
                    </div>
                    {/*<a href="#anc-legal" className="nav-menu">Legal Consulting</a>*/}
                    <Links to="/ko#anc-values" className="nav-menu">회사의 가치</Links>
                    <Links to="/ko#anc-history" className="nav-menu">회사의 역사</Links>
                    <Links to="/ko#anc-location" className="nav-menu">문의하기</Links>
                    <div className="nav-lang">
                        <div className="globe-div">
                            <img src={Globe} className="lang-globe"/>
                        </div>
                        <div className="nav-lang-box">
                            <div className="nav-lang-drop">
                                <Links to="/#anc-home" className="lang-icons">
                                    <img src={LangEN} className="lang-icon"/>
                                    <div className="langs">English</div>
                                </Links>
                                <div className="lang-div"></div>
                                <Links to="/id#anc-home" className="lang-icons">
                                    <img src={LangID} className="lang-icon"/>
                                    <div className="langs">Bahasa</div>
                                </Links>
                                <div className="lang-div"></div>
                                <Links to="/ko#anc-home" className="lang-icons">
                                    <img src={LangKO} className="lang-icon"/>
                                    <div className="langs" to="/ko#anc-home">한국어</div>
                                </Links>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar