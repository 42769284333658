import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';


const SCompany = () => {
    return (
        <>
            <div className="sec-services">
                <div id="service-invest" className="hero-services ser-investment">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                투자 및 사모펀드
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            인도네시아는 세계에서 4번째로 인구가 많고 동남아시아에서 가장 큰 경제를 가지고 있습니다. 그 어느 때보다 중산층이 증가하고 있고 동남아시아에서 IT에 가장 많은 비용을 지출하고 있는 나라 중 하나로서, 세계에서 가장 큰 경제 성장 잠재력을 보유한 나라 중 하나로 인도네시아는 현재 GDP 1조 달러 이상의 세계 16위 경제 대국입니다(2020년).
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">지원 서비스</h2>
                        </div>
                    </div>
                    <div className="investment-box">
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    전통시장
                                </p>
                                <p className="visa-types-tag">
                                    암호화폐
                                </p>
                            </div>
                            <p className="investment-grid-title ko">
                                시장 조사 인텔리전스
                            </p>
                            <p className="investment-grid-desc ko">
                                우리의 철저한 시장 조사를 통해 동향을 예측하여 제품과 서비스를 적절하게 조정할 수 있습니다.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    전통시장
                                </p>
                                <p className="visa-types-tag">
                                    암호화폐
                                </p>
                            </div>
                            <p className="investment-grid-title ko">
                                경쟁 조사
                            </p>
                            <p className="investment-grid-desc ko">
                                다년간의 경험을 바탕으로, 우리 연구팀은 주요 경쟁사의 전체 목록을 제공하고 최고의 진입 전략을 마련합니다.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    전통시장
                                </p>
                                <p className="visa-types-tag">
                                    암호화폐
                                </p>
                            </div>
                            <p className="investment-grid-title ko">
                                시장 진입
                            </p>
                            <p className="investment-grid-desc ko">
                                우리는 고객이 모든 사무 절차를 처리하고 현지 법과 규정을 준수할 수 있는 최고의 진입 전략을 마련합니다.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    암호화폐
                                </p>
                            </div>
                            <p className="investment-grid-title ko">
                                자산 관리
                            </p>
                            <p className="investment-grid-desc ko">
                                우리의 암호화폐 거래 전문가들은 낮은 위험과 유기적인 전략으로 자산을 안전하게 관리하기 위해 기술적 분석과 시장 분석 모두 활용합니다.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    암호화폐
                                </p>
                            </div>
                            <p className="investment-grid-title ko">
                                위험 평가
                            </p>
                            <p className="investment-grid-desc ko">
                                Valorem의 위험 평가는 암호화폐 시장의 시장 조작 위험과 높은 변동성을 깊이 연구하여 암호화폐 자산을 안전하게 보호합니다.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    암호화폐
                                </p>
                            </div>
                            <p className="investment-grid-title ko">
                                유동성 컨설팅
                            </p>
                            <p className="investment-grid-desc ko">
                                우리는 의미 있는 거래량을 확보하기 위해 비정상적인 자기거래에 맞서 실제 거래량을 유도하고 증가시킵니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany