import Logo from '../img/valorem_logo_white.svg'
import { BrowserRouter, Link, Route } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link';

const Footer = () => {
    return (
        <div className="sec-footer dpf-ccc">
            <div className="div-footer dpf-ccs">
                <div className='footer-top dpf-ccc'>
                    <div className="footer-left dpf-ccc">
                        <img className="footer-logo" src={Logo}/>
                        <p className="footer-desc">Inspire And Empower Your Value</p>
                    </div>
                    <div className="footer-right">
                        <div className="footer-menus dpf-rcc">
                            <Links to="/ko/#anc-home" className="footer-menu">홈</Links>
                            <div className="footer-division"></div>
                            <Links to="/ko/#anc-about" className="footer-menu">소개</Links>
                            <div className="footer-division"></div>
                            <Links to="/ko/services/company-registration#serv-company" className="footer-menu">서비스</Links>
                            <div className="footer-division"></div>
                            <Links to="/ko/#anc-values" className="footer-menu">회사의 가치</Links>
                            <div className="footer-division"></div>
                            <Links to="/ko/#anc-history" className="footer-menu">회사의 역사</Links>
                            <div className="footer-division"></div>
                            <Links to="/ko/#anc-location" className="footer-menu">문의하기</Links>
                        </div>
                    </div>
                </div>
                <div className="footer-bot dpf-ccc">
                    <div className="footer-d"></div>
                    <p className="copyright">2022 &copy; The Valorem Partners. All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer