import Logo from './img/valorem-icon-white.svg'
import { BrowserRouter, Link, Route } from "react-router-dom";
import React, {useState} from 'react';
import Hamb from './img/iconOpen.png'
import Close from './img/iconClose.png'
import { HashLink as Links } from 'react-router-hash-link';

const MobNav = () => {

    const [menuO,setMenuO] = useState('flex');
    const [menuC,setMenuC] = useState('none');
    const [menuN, setMenuN] = useState('none');

    const handleClick = (index) => {
        switch (index) {
            case 0:
                setMenuO('flex');
                setMenuC('none');
                return;
            case 1:
                setMenuO('none');
                setMenuC('flex');
                return;
            case 2:
                setMenuN('none');
                return;
            case 3:
                setMenuN('flex');
                setMenuO('flex');
                setMenuC('none');
                return;
        }
    }

    return (
        <>
            <div className="mobile sec-mobnav">
                <div className="div-mobnav">
                    <Link to="/"  onClick={()=>handleClick(0)}>
                        <img src={Logo} className="navlogo"/>
                    </Link>
                    <div className="mob-menu-oc">
                        <img style={{display: menuO}} src={Hamb} className="mobMenus"  onClick={()=>handleClick(1)} />
                        <img style={{display: menuC}} src={Close} className="mobMenus" onClick={()=>handleClick(0)}/>
                    </div>
                </div>
            </div>
            <div style={{display: menuC}} className="div-mobSub">
                <div className="mobSubs">
                    <Links to="/#anc-home" className="mobSub" onClick={()=>handleClick(0)}>Home</Links>
                    <Links to="/#anc-about" className="mobSub" onClick={()=>handleClick(0)}>About Us</Links>
                    <Links to="/#anc-biz" className="mobSub" onClick={()=>handleClick(0)}>Services</Links>
                    <div className="mobnav-sub">
                        <Links className="mobnav-subs" to="/services/company-registration#serv-company"  onClick={()=>handleClick(0)}>Company Registration</Links>
                        <Links className="mobnav-subs" to="/services/visa-documentation#ser-visa" onClick={()=>handleClick(0)}>Visa Documentation</Links>
                        <Links className="mobnav-subs" to="/services/investment-pe#service-invest" onClick={()=>handleClick(0)}>Investment & Private Equity</Links>
                        <Links className="mobnav-subs" to="/services/tax-accounting#service-tax" onClick={()=>handleClick(0)}>Tax & Accounting</Links>
                        <Links className="mobnav-subs" to="/services/merges-acquisitions#service-ma" onClick={()=>handleClick(0)}>Merges & Acquisitions</Links>
                    </div>
                    <Links to="/#anc-values" className="mobSub" onClick={()=>handleClick(0)}>Our Values</Links>
                    <Links to="/#anc-history" className="mobSub" onClick={()=>handleClick(0)}>Our History</Links>
                    <Links to="/#anc-location" className="mobSub" onClick={()=>handleClick(0)}>Contact Us</Links>
                    <div className="moblangs">
                        <Link to="/" className="moblang" onClick={()=>handleClick(0)}>EN</Link>
                        <div className="moblang-div"></div>
                        <Link to="/id" className="moblang" onClick={()=>handleClick(0)}>ID</Link>
                        <div className="moblang-div"></div>
                        <Link to="/ko" className="moblang" onClick={()=>handleClick(0)}>KO</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobNav