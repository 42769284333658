import Logo from '../img/valorem_logo_white.svg'

const Hero = () => {
    return (
        <div id="anc-home" className="sec-hero">
            <div className="sec-layer"></div>
            <div className="div-hero dpf-ccc">
                <img src={Logo} className="hero-logo"/>
                <div className="hero-line"></div>
                <h1>Inspire And Empower Your Value</h1>
            </div>
        </div>
    )
}

export default Hero