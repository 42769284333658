import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div id="service-tax" className="sec-services">
                <div className="hero-services ser-tax">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                세무 및 회계
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            세무 및 회계 처리는 어디에서나 쉽지 않지만 사업을 할 때 매우 중요합니다. Valorem의 법률 및 비즈니스 컨설턴트팀은 최고의 도구와 시장 경험을 바탕으로 장부를 정리할 수 있습니다. 우리는 개인 소득세, 법인세, 재산세에 대한 서비스를 제공합니다.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">세무 및 회계 서비스</h2>
                        </div>
                        <div className="tax-desc-box">
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    개인 소득세
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc ko">
                                        개인 소득세는 인도네시아 정부에 납부 의무가 있는 기업이 원천 징수하는 경우가 많습니다. 거주 납세자 또는 비거주 납세자일 경우, 소득 및 고용 상태에 따라 5%에서 30%까지 세액이 달라질 수 있습니다. 표준 세금 규정 외에도 비과세 소득 5,400만 루피아 등 세금을 더 공제할 수 있는 여러 가지 방법이 있습니다.
                                    </p>
                                </div>
                            </div>
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    법인세
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc ko">
                                        법인세는 고려해야 할 변수가 너무 많아 본질적으로 더 복잡합니다. 인도네시아의 모든 기업에 적용되는 표준세율 25% 외에도 세율을 더욱 낮추기 위한 여러 가지 면제 및 혜택이 있습니다. 국내외 기업 모두 납세 의무가 있지만, 외국 기업의 경우는 종종 더 복잡합니다. 인도네시아에 상설 기구가 없어도 외국 기업들은 여전히 세금을 납부해야 합니다.
                                    </p>
                                </div>
                            </div>
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    재산세
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc ko">
                                        지속적인 경제 성장과 관광의 중심지로 여겨지면서, 많은 외국인 투자자들은 인도네시아로 자본을 옮겼습니다. 특히 발리에서는 발생할 수 있는 모든 부동산 관련 세금 부채를 이해하는 것이 중요합니다. 인도네시아의 재산세는 크게 임대세, 토지 및 건물세, 건설세로 나뉩니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany