import HImg from '../img/history-imgKO.png'
import Rec from "../img/side-rec.svg";
import Hmob from '../img/history-mobKO.png'

const History = () => {
    return (
        <div id="anc-history" className="sec-history dpf-ccc">
            <div className="div-history dpf-ccc">
                <div className="title-box dpf-rcc value-box">
                    <img src={Rec} className="side-rec"/>
                    <h2 className="title-h2 t-white">회사의 역사</h2>
                </div>
                <img src={HImg} className="history-img desktop"/>
                <img src={Hmob} className="history-mob mobile"/>
            </div>
        </div>
    )
}

export default History