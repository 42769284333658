

import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div id="service-ma" className="sec-services">
                <div className="hero-services ser-ma">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                MERGER & AKUISISI
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Valorem membantu berbagai perusahaan meningkatkan nilai kesepakatan dan kecepatan M&A setiap tahunnya di pasar Indonesia yang dinamis. Sebagai rekan dalam setiap siklus transaksi, kami dapat mengidentifikasi peluang pertumbuhan dalam sektor tertentu hingga peningkatan fungsi bisnis.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">LAYANAN M&A</h2>
                        </div>
                    </div>
                    <div className="ma-guide-div">
                        <div className="ma-guide">
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    Merger
                                </p>
                                <p className="ma-guide-desc">
                                    Seluruh aset dan liabilitas dialihkan ke satu bagian bersama dengan perusahaan yang sepenuhnya dilikuidasi.
                                </p>
                            </div>
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    Konsolidasi
                                </p>
                                <p className="ma-guide-desc">
                                    Semua perusahaan sepenuhnya yang terlikuidasi membentuk entitas baru. Seluruh aset dan liabilitas sepenuhnya ditransfer ke entitas yang baru dibentuk.
                                </p>
                            </div>
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    Akuisisi Saham atau Aset
                                </p>
                                <p className="ma-guide-desc">
                                    Suatu pihak memperoleh saham atau aset perusahaan yang mempengaruhi perubahan pengendalian.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ma-box">
                        <div className="title-box-services dpf-rcs ma-40">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">PEDOMAN HUKUM & REGULASI</h2>
                        </div>
                        <p className="ma-laws">
                            1.&nbsp;&nbsp;&nbsp; Undang-undang Nomor 5 Tahun 1999 tentang Pasar Modal (Hukum Pasar Modal)
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            2.&nbsp;&nbsp;&nbsp; Peraturan Pemerintah Nomor 27 Tahun 1998 tentang Penggabungan, Peleburan, Dan Pengambilalihan Perseroan Terbatas
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            3.&nbsp;&nbsp;&nbsp; Undang-undang Nomor 5 Tahun 1999 tentang Larangan Praktek Monopoli dan Persaingan Usaha Tidak Sehat
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            4.&nbsp;&nbsp;&nbsp; Undang-undang Nomor 13 Tahun 2003 tentang Ketenagakerjaan
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            5.&nbsp;&nbsp;&nbsp; Undang-undang Nomor 25 Tahun 2007 tentang Penanaman Modal
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            6.&nbsp;&nbsp;&nbsp; Undang-undang Nomor 40 Tahun 2007 tentang Perseroan Terbatas (Hukum Perusahaan)
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            7.&nbsp;&nbsp;&nbsp; Peraturan Pemerintah Nomor 57 Tahun 2010 tentang Penggabungan atau Peleburan Badan Usaha dan Pengambilalihan Saham Perusahaan yang dapat Mengakibatkan Terjadinya Praktik Monopoli dan Persaingan Usaha Tidak Sehat
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            8.&nbsp;&nbsp;&nbsp; Peraturan KPPU Nomor 3 Tahun 2019 tentang Penilaian Terhadap Penggabungan atau Peleburan Badan Usaha, atau Pengambilalihan Saham Perusahaan yang Dapat Mengakibatkan Terjadinya Praktik Monopoli dan/atau Persaingan Usaha Tidak Sehat
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            9.&nbsp;&nbsp;&nbsp; Peraturan OJK Nomor. 74/POJK.04/2016 tentang Penggabungan Usaha atau Peleburan Usaha
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            10.&nbsp;&nbsp;&nbsp; Peraturan OJK Nomor. 9/POJK.04/2018 tentang Pengambilalihan Perusahaan Terbuka
                        </p>
                        <div className="ma-box-line"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany