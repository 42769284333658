import VIcon from '../img/valorem-icon-black.svg'
import Values from '../img/valuesID.png'
import Icon from '../img/valorem-icon-black.svg'
import Rec from "../img/side-rec-black.svg";

const Value = () => {
    return (
        <div id="anc-values" className="sec-value">
            <div className="div-value">
                <div id="mobile" className="title-box-biz dpf-rcs m-20">
                    <img src={Rec} className="side-rec"/>
                    <h2 className="title-h2">KEUNGGULAN KAMI</h2>
                </div>
                <div className="desktop value-title-box">
                    <p className="value-title">KEUNGGULAN<br/>KAMI</p>
                    <img src={VIcon} className="value-icon"/>
                </div>
                <div className="value-content-box">
                    <img src={Values} className="value-img desktop"/>
                    <div className="div-value-mob mobile">
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">Kesesuaian</p>
                        </div>
                        <p className="value-desc">Kami menyesuaikan minat dan pola pikir sesuai dengan klien kami untuk memberikan layanan yang optimal
                        </p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">Sinergi</p>
                        </div>
                        <p className="value-desc">Kemitraan kami melampaui kontrak untuk lebih banyak peluang di masa depan
                        </p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">Integritas</p>
                        </div>
                        <p className="value-desc">Kami menyuguhkan standar etika dan profesional tertinggi tanpa kompromi
                        </p>
                        <div className="value-line"></div>
                        <div className="div-value-title">
                            <img src={Icon} className="value-ic"/>
                            <p className="value-ti">Kecerdasan</p>
                        </div>
                        <p className="value-desc">Kami membangun dan menjaga kerja sama untuk memperlancar kebutuhan bisnis Anda secara tepat
                        </p>
                        <div className="value-line"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Value