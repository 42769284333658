import './Tab.css';
import { BrowserRouter, Link, Route } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link';


const ServiceTab = () => {
    return (
        <>
            <div className="desktop sec-tab">
                <div className="div-tab">
                    <Links to="/id/services/company-registration#serv-company" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">JASA PENDIRIAN PERUSAHAAN</span>
                    </Links>
                    <Links to="/id/services/visa-documentation#ser-visa" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">PENGURUSAN VISA</span>
                    </Links>
                    <Links to="/id/services/investment-pe#service-invest" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">INVESTASI & PRIVATE EQUITY</span>
                    </Links>
                    <Links to="/id/services/tax-accounting#service-tax" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">PAJAK & AKUNTANSI</span>
                    </Links>
                    <Links to="/id/services/merges-acquisitions#service-ma" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">MERGER & AKUISISI</span>
                    </Links>
                </div>
            </div>
            <div className="mobile sec-tab">
                <div className="div-tab">
                    <Link to="/id/services/company-registration" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">JASA PENDIRIAN<br/>PERUSAHAAN</span>
                    </Link>
                    <Link to="/id/services/visa-documentation" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">PENGURUSAN<br/>VISA</span>
                    </Link>
                    <Link to="/id/services/investment-pe" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">INVESTASI &<br/>PRIVATE EQUITY</span>
                    </Link>
                    <Link to="/id/services/tax-accounting" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">PAJAK &<br/>AKUNTANSI</span>
                    </Link>
                    <Link to="/id/services/merges-acquisitions" className="s-tabs">
                        <div className="s-tabs-bg"></div>
                        <span className="tab-title">MERGER &<br/>AKUISISI</span>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ServiceTab