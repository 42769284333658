import ServiceTab from './ServiceTab.js';
import './Services.css'
import '../../Mobile.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';


const SCompany = () => {
    return (
        <>
        <div className="sec-services">
            <div id="serv-company" className="hero-services ser-company">
                <div className="hero-services-bg"></div>
                <div className="hero-services-cont">
                    <div className="services-title-box">
                        <img src={RecW} className="side-rec"/>
                        <h2 className="services-title">
                            회사 등기
                        </h2>
                    </div>
                </div>
            </div>
            <ServiceTab />
            <div className="div-services">
                <div className="service-desc-div">
                    <p className="service-desc">
                        인도네시아에서 사업을 시작하기 위한 첫 단계는 회사 설립입니다. 인도네시아에서 창업을 하기 위해 고객의 투자 요구에 따라 최적의 회사 유형을 선택하고 필요한 모든 허가와 서류를 취득하는 방법을 조언합니다. 우리의 전문가들은 고객의 요구를 파악하고 그에 따라 서비스를 맞춤화하기 위해 지속적으로 고객과 소통합니다. 회사 등기 절차는 상황에 따라 달라집니다.
                    </p>
                    <div className="title-box-services dpf-rcs">
                        <img src={Rec} className="side-rec"/>
                        <h2 className="title-h2">회사 유형</h2>
                    </div>
                    <div className="company-types">
                        <div className="company-type">
                            <p className="visa-types-tag">
                                PMA
                            </p>
                            <p className="c-type-title ko">
                                해외 직접 투자
                            </p>
                            <p className="c-type-desc ko">
                                PMA는 인도네시아에서 100% 외국인 소유 회사를 원하는 외국 기업이나 개인에게 가장 좋은 옵션입니다. 일부 부문에서는 100% 외국인 소유 기업의 활동을 허용하지 않기 때문에, PMA 기업은 제한에 직면할 수 있습니다.
                            </p>
                        </div>
                        <div className="company-type">
                            <p className="visa-types-tag">
                                PMDN
                            </p>
                            <p className="c-type-title ko">
                                현지 회사
                            </p>
                            <p className="c-type-desc ko">
                                PMDN은 현지 투자(개인 및 기업)가 100% 소유한 회사입니다. PMDN 회사는 PMA 회사와 비교할 때 더 적은 요건으로 빠르게 설립될 수 있습니다.
                            </p>
                        </div>
                        <div className="company-type">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    KPPA
                                </p>
                                <p className="visa-types-tag">
                                    KP3A
                                </p>
                                <p className="visa-types-tag">
                                    BUJKA
                                </p>
                            </div>
                            <p className="c-type-title ko">
                                대표 사무소(RO)
                            </p>
                            <p className="c-type-desc ko">
                                대표 사무실은 위험이 낮은 인도네시아 시장을 개척하고자 하는 사람들에게 가장 좋은 옵션입니다. 가장 일반적인 RO 유형은 일반(KPPA), 상업(KP3A), 건설회사(BUJKA)입니다.
                            </p>
                        </div>
                    </div>
                    <div className="title-box-services dpf-rcs">
                        <img src={Rec} className="side-rec"/>
                        <h2 className="title-h2">비교표</h2>
                    </div>
                    <div className="table-hor">
                        <table className="company-table">
                            <tr>
                                <th className="companyre-con noborder-l padding-inline ko">
                                    회사 유형
                                </th>
                                <th className="companyre-con ko">
                                    특성
                                </th>
                                <th className="companyre-con ko">
                                    구조
                                </th>
                                <th className="companyre-con ko">
                                    자본 요구사항
                                </th>
                            </tr>
                            <tr>
                                <td className="noborder-l">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">PMA</p>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">수익 창출 사업 활동을 허용합니다.
                                        </li>
                                        <li className="companyre-li">최대 100% 외국인 소유를 허용합니다.
                                        </li>
                                        <li className="companyre-li">외국인 투자에 개방된 사업 부문에 이상적입니다.
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">최소 2명의 주주(법인/개인)가 필요합니다.
                                        </li>
                                        <li className="companyre-li">현지 이사 1명 이상이 필요합니다.
                                        </li>
                                        <li className="companyre-li">위원 1명 이상이 필요합니다(유효한 근무 허가를 받은 외국인 가능).
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-capital">
                                        <li className="companyre-li">최소 100억 IDR 투자가 필요합니다.
                                        </li>
                                        <li className="companyre-li">최소 100억 IDR 이 완납 자본으로 제공되어야 합니다.
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="noborder-l">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">PMDN</p>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">외국인 직원은 중대형 기업에서만 허용됩니다.
                                        </li>
                                        <li className="companyre-li">외국인 소유는 허용되지 않습니다.
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">최소 2 명의 주주(법인/개인) 가 필요합니다.
                                        </li>
                                        <li className="companyre-li">현지 이사 1명 이상이 필요합니다.
                                        </li>
                                        <li className="companyre-li">위원 1명 이상이 필요합니다(유효한 근무 허가를 받은 외국인 가능).
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="companytype-capital">
                                        <li className="companyre-li">소형: IDR 10억~50억
                                        </li>
                                        <li className="companyre-li">중형: IDR 50억~100억
                                        </li>
                                        <li className="companyre-li">대형: IDR 100억~
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="noborder-l noborder-b">
                                    <ul className="companytype-title">
                                        <p className="company-title-height">대표 사무소(RO)</p>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-nature">
                                        <li className="companyre-li">재정 수익 활동에 대한 제한이 없습니다.
                                        </li>
                                        <li className="companyre-li">100% 외국인 서유가 허용됩니다.
                                        </li>
                                        <li className="companyre-li">시장 조사 활동에 집중합니다.
                                        </li>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-structure">
                                        <li className="companyre-li">주주 및 이사가 필요하지 않습니다.
                                        </li>
                                        <li className="companyre-li">한 명의 최고 경영자로 충분합니다(KPPA & KP3A)
                                        </li>
                                    </ul>
                                </td>
                                <td className="noborder-b">
                                    <ul className="companytype-capital">
                                        <p className="companyre-li">-
                                        </p>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default SCompany