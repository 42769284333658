import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout.js";
import LayoutKO from "./components-ko/Layout.js";
import LayoutID from "./components-id/Layout.js";
import Home from "./components/Home";
import SCompany from "./components/Services/SCompany.js";
import SVisa from "./components/Services/SVisa.js";
import SInvestment from "./components/Services/SInvestment.js";
import STax from './components/Services/STax.js';
import SMA from './components/Services/SMA.js';
import HomeKO from "./components-ko/Home";
import SCompanyKO from "./components-ko/Services/SCompany.js";
import SVisaKO from "./components-ko/Services/SVisa.js";
import SInvestmenKO from "./components-ko/Services/SInvestment.js";
import STaxKO from './components-ko/Services/STax.js';
import SMAKO from './components-ko/Services/SMA.js';
import HomeID from "./components-id/Home";
import SCompanyID from "./components-id/Services/SCompany.js";
import SVisaID from "./components-id/Services/SVisa.js";
import SInvestmentID from "./components-id/Services/SInvestment.js";
import STaxID from './components-id/Services/STax.js';
import SMAID from './components-id/Services/SMA.js';


export default function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="services/company-registration" element={<SCompany />} />
                    <Route path="services/visa-documentation" element={<SVisa/>} />
                    <Route path="services/investment-pe" element={<SInvestment/>} />
                    <Route path="services/tax-accounting" element={<STax/>} />
                    <Route path="services/merges-acquisitions" element={<SMA/>} />
                </Route>
                <Route path="/ko" element={<LayoutKO />}>
                    <Route path="" element={<HomeKO />} />
                    <Route path="services/company-registration" element={<SCompanyKO />} />
                    <Route path="services/visa-documentation" element={<SVisaKO/>} />
                    <Route path="services/investment-pe" element={<SInvestmenKO />} />
                    <Route path="services/tax-accounting" element={<STaxKO/>} />
                    <Route path="services/merges-acquisitions" element={<SMAKO/>} />
                </Route>
                <Route path="/id" element={<LayoutID />}>
                    <Route path="" element={<HomeID />} />
                    <Route path="services/company-registration" element={<SCompanyID />} />
                    <Route path="services/visa-documentation" element={<SVisaID/>} />
                    <Route path="services/investment-pe" element={<SInvestmentID/>} />
                    <Route path="services/tax-accounting" element={<STaxID/>} />
                    <Route path="services/merges-acquisitions" element={<SMAID/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));