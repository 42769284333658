import Logo from '../img/valorem_logo_white.svg'
import { BrowserRouter, Link, Route } from "react-router-dom";
import { HashLink as Links } from 'react-router-hash-link';

const Footer = () => {
    return (
        <div className="sec-footer dpf-ccc">
            <div className="div-footer dpf-ccs">
                <div className='footer-top dpf-ccc'>
                    <div className="footer-left dpf-ccc">
                        <img className="footer-logo" src={Logo}/>
                        <p className="footer-desc">Inspire And Empower Your Value</p>
                    </div>
                    <div className="footer-right">
                        <div className="footer-menus dpf-rcc">
                            <Links to="/#anc-home" className="footer-menu">Home</Links>
                            <div className="footer-division"></div>
                            <Links to="/#anc-about" className="footer-menu">About Us</Links>
                            <div className="footer-division"></div>
                            <Links to="/services/company-registration#serv-company" className="footer-menu">Services</Links>
                            <div className="footer-division"></div>
                            <Links to="/#anc-values" className="footer-menu">Our Values</Links>
                            <div className="footer-division"></div>
                            <Links to="/#anc-history" className="footer-menu">Our History</Links>
                            <div className="footer-division"></div>
                            <Links to="/#anc-location" className="footer-menu">Contact Us</Links>
                        </div>
                    </div>
                </div>
                <div className="footer-bot dpf-ccc">
                    <div className="footer-d"></div>
                    <p className="copyright">2022 &copy; The Valorem Partners. All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer