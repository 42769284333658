import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';


const SCompany = () => {
    return (
        <>
            <div className="sec-services">
                <div id="service-invest" className="hero-services ser-investment">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                INVESTASI & PRIVATE EQUITY
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Indonesia memiliki populasi terbesar ke-4 di dunia dan ekonomi terbesar di Asia Tenggara.
                            Dengan kelas menengah yang terus berkembang dan sebagai salah satu pengguna Teknologi Informasi (IT) terbesar di kawasan ini, Indonesia memiliki salah satu potensi pertumbuhan ekonomi terbesar di dunia.
                            Saat ini Indonesia merupakan negara dengan ekonomi terbesar ke-16 di dunia yang memiliki PDB lebih dari $1 triliun (2020).
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">LAYANAN PENDUKUNG</h2>
                        </div>
                    </div>
                    <div className="investment-box">
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    TRADISIONAL
                                </p>
                                <p className="visa-types-tag">
                                    KRIPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Riset Intelejensi Pasar
                            </p>
                            <p className="investment-grid-desc">
                                Riset pasar yang dilakukan secara menyeluruh, membantu Anda memprediksi tren pasar untuk menyesuaikan produk dan layanan mereka.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    TRADISIONAL
                                </p>
                                <p className="visa-types-tag">
                                    KRIPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Kompetisi Penelitian
                            </p>
                            <p className="investment-grid-desc">
                                Dengan pengalaman bertahun-tahun, tim peneliti kami menyediakan daftar lengkap pesaing utama dan memberikan saran strategi terbaik agar dapat unggul dalam kompetisi bisnis.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    TRADISIONAL
                                </p>
                                <p className="visa-types-tag">
                                    KRIPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Memasuki Segmen Pasar
                            </p>
                            <p className="investment-grid-desc">
                                Kami hadir dengan memberikan strategi terbaik bagi Anda untuk berurusan dengan semua birokrasi serta mematuhi hukum dan peraturan setempat.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    KRIPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Manajemen Aset
                            </p>
                            <p className="investment-grid-desc">
                                Pakar trader kripto kami memanfaatkan analisis teknis dan pasar untuk mengelola aset secara aman dengan risiko rendah dan strategi yang organik.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    KRIPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Penilaian Risiko
                            </p>
                            <p className="investment-grid-desc">
                                Penilaian risiko Valorem dilakukan dengan mempelajari risiko pasar aset kripto secara mendalam terhadap manipulasi pasar dan volatilitas tinggi demi melindungi aset kripto Anda dengan aman.
                            </p>
                        </div>
                        <div className="investment-grids">
                            <div className="visa-types-tags">
                                <p className="visa-types-tag">
                                    KRIPTO
                                </p>
                            </div>
                            <p className="investment-grid-title">
                                Konsultasi Likuiditas
                            </p>
                            <p className="investment-grid-desc">
                                Kami mendorong peningkatan volume transaksi secara lebih nyata guna menghindari perdagangan yang tidak normal untuk mengamankan volume transaksi yang sewajarnya.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany