import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div id="service-tax" className="sec-services">
                <div className="hero-services ser-tax">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                PAJAK & AKUNTANSI
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Berurusan dengan pajak dan akuntansi menjadi hal yang menakutkan bagi Anda, namun kedua hal tersebut merupakan aspek yang penting ketika menjalankan bisnis.
                            Tim konsultan hukum dan bisnis Valorem dibekali dengan peralatan yang lengkap serta pengalaman terbaik di pasar untuk membantu mengatur pembukuan Anda.
                            Kami menawarkan layanan pajak penghasilan pribadi, pajak perusahaan, dan pajak properti.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">LAYANAN PAJAK & AKUNTANSI</h2>
                        </div>
                        <div className="tax-desc-box">
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    Pajak Pendapatan Pribadi
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc">
                                        Pajak penghasilan pribadi sering kali dipotong oleh perusahaan yang wajib membayar kepada pemerintah Indonesia.
                                        Jumlah pajak dapat bervariasi dari 5% hingga 30% sesuai dengan pendapatan serta status karyawan, yang diantaranya ialah wajib pajak dalam negeri atau wajib pajak bukan penduduk.
                                        Selain itu peraturan perpajakan standar seperti penghasilan tidak kena pajak sebesar 54 juta rupiah, kami masih mempunyai banyak cara untuk mengurangi pajak.
                                    </p>
                                </div>
                            </div>
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    Pajak Perusahaan
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc">
                                        Pajak perusahaan pada dasarnya lebih rumit, oleh sebab terlalu banyaknya variabel yang harus dipertimbangkan.
                                        Selain tarif pajak standar sebanyak 25% yang berlaku untuk semua perusahaan di Indonesia, ada beberapa pengecualian dan manfaat untuk menurunkan tarif pajak.
                                        Perusahaan dalam dan luar negeri wajib membayar pajak, beberapa perusahaan asing seringkali menghadapi keadaan yang lebih rumit.
                                        Meski tidak memiliki bentuk usaha tetap di Indonesia, perusahaan asing tetap harus menyelesaikan kewajiban perpajakannya.
                                    </p>
                                </div>
                            </div>
                            <div className="tax-desc">
                                <p className="tax-ser-title">
                                    Pajak Properti
                                </p>
                                <div className="tax-desc-div">
                                    <div className="tax-line"></div>
                                    <p className="tax-ser-desc">
                                        Dengan pertumbuhan ekonomi yang konstan dan dipandang sebagai pusat pariwisata, banyak investor asing yang berinvestasi di Indonesia.
                                        Khususnya di Bali, penting untuk memahami semua kewajiban pajak terkait properti.
                                        Beberapa pajak properti tersebut diantaranya ialah Pajak Sewa, Pajak Bumi dan Bangunan, dan Pajak Konstruksi.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany