import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div className="sec-services">
                <div className="hero-services ser-visa">
                    <div id="ser-visa" className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                VISA DOCUMENTATION
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Our team of VISA and Immigration consultants have years of experience in the area having processed thousands of VISA to date.
                            We ensure all companies and individuals that all VISA and needed documentations are set up correctly with compliance with local laws.
                            Especifically for companies, we create a comprehensive report based on your company's profile to offer full support on working visa acquirement.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">VISA TYPES</h2>
                        </div>
                        <div className="visa-types-grid">
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                <p className="visa-grid-p">
                                    Working Visa
                                </p>
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                <p className="visa-grid-p">
                                    Freelance Work Visa
                                </p>
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    B211
                                </p>
                                Single Entry Visa
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    D212
                                </p>
                                Multiple Entry Visa
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Investor Visa
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Retirement Visa
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Dependent Visa
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Spouse Visa
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Study Visa
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAP
                                </p>
                                Permanent Stay Visa
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Calling Visa
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    VOA
                                </p>
                                Visa on Arrival Extension
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Deportation Clearance
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Immigration Clearance
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Overstay Clearance
                            </div>
                            <div className="visa-types-grid-p">
                                {/*<p className="visa-types-tag">*/}
                                {/*    -*/}
                                {/*</p>*/}
                                Immigration Consultation
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    KITAS
                                </p>
                                Citizenship
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    SKJ
                                </p>
                                Travel Permit Certificate
                            </div>
                            <div className="visa-types-grid-p">
                                <p className="visa-types-tag">
                                    SKCK
                                </p>
                                Police Clearance Certificate
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany