

import ServiceTab from './ServiceTab.js';
import './Services.css'
import Rec from "../../img/side-rec-black.svg";
import RecW from "../../img/side-rec.svg";
import Footer from '../Footer';
import SRec from '../../img/service-rec.svg';


const SCompany = () => {
    return (
        <>
            <div id="service-ma" className="sec-services">
                <div className="hero-services ser-ma">
                    <div className="hero-services-bg"></div>
                    <div className="hero-services-cont">
                        <div className="services-title-box">
                            <img src={RecW} className="side-rec"/>
                            <h2 className="services-title">
                                MERGES & ACQUISITIONS
                            </h2>
                        </div>
                    </div>
                </div>
                <ServiceTab />
                <div className="div-services">
                    <div className="service-desc-div">
                        <p className="service-desc">
                            Valorem helps companies every year increase M&A deal value and speed in fast-changing markets in Indonesia. As a journey partner across the transaction life cycle, we can identify both growth opportunities within specific sectors and improvements within business functions.
                        </p>
                        <div className="title-box-services dpf-rcs">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">M&A SERVICES</h2>
                        </div>
                    </div>
                    <div className="ma-guide-div">
                        <div className="ma-guide">
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    Mergers
                                </p>
                                <p className="ma-guide-desc">
                                    All assets and liabilities are fully transferred to one part with the reamining company being fully liquidated.
                                </p>
                            </div>
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    Consolidations
                                </p>
                                <p className="ma-guide-desc">
                                    All companies are fully liquidated forming a new entity. All assets and liabilities are fully transferred to the newly formed entity.
                                </p>
                            </div>
                            <div className="ma-guide-box">
                                <p className="ma-guide-title">
                                    Share or Asset Acquisitions
                                </p>
                                <p className="ma-guide-desc">
                                    A party acquires shares or assets of a company resulting in a change of control.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ma-box">
                        <div className="title-box-services dpf-rcs ma-40">
                            <img src={Rec} className="side-rec"/>
                            <h2 className="title-h2">KEY LAWS & REGULATIONS</h2>
                        </div>
                        <p className="ma-laws">
                            1.&nbsp;&nbsp;&nbsp; Law No 8 of 1995 on the Capital Markets (Capital Markets Law)
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            2.&nbsp;&nbsp;&nbsp; Government Regulation 27 of 1998 on Merger, Consolidation, and Acquisition of a Limited Liability Company
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            3.&nbsp;&nbsp;&nbsp; Law No 5 of 1999 on Prohibition of Monopoly and Unfair Business Competition
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            4.&nbsp;&nbsp;&nbsp; Law No. 13 of 2003 on Manpower
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            5.&nbsp;&nbsp;&nbsp; Law No 25 of 2007 on Investment
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            6.&nbsp;&nbsp;&nbsp; Law No 40 of 2007 on Limited Liability Companies (Company Law)
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            7.&nbsp;&nbsp;&nbsp; Government Regulation 57 of 2010 on Mergers or Consolidations of Business Entities and Acquisitions of Company Shares Which May Result in Monopolistic Practices and/or Unfair Business Competition
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            8.&nbsp;&nbsp;&nbsp; KPPU Law No 3 of 2019 on Assessment of Mergers or Consolidations or Acquisitions of Companies That May Result in the Occurrence of Monopolistic Practices and/or Unfair Business Competition
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            9.&nbsp;&nbsp;&nbsp; OJK Regulation No. 74/POJK.04/2016 on Merger or Consolidation of a Public Company
                        </p>
                        <div className="ma-box-line"></div>
                        <p className="ma-laws">
                            10.&nbsp;&nbsp;&nbsp; OJK Regulation No. 9/POJK.04/2018 on Public Company Takeover
                        </p>
                        <div className="ma-box-line"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SCompany